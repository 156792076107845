.Login-box-background {
  background-color: dimgrey;
  border-radius: 0.5vh;
  padding: 1vh;
  width: 15vw;
  height: 15vh;
}

.Login-form {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
}

.Login-input {
  text-align: center;
  color: #fff;
  margin: 0.2vw;
  font-size: xx-large;
  font-family: ".SF NS Mono", serif;
}

.Login-submit {
  padding: 10vh;
}

input[type="button"] {
  margin-top: 1vh;
  font-family: ".SF NS Mono", serif;
  font-size: xx-large;
  align-self: center;
  width: max-content;
}
